// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---components-development-logo-page-tsx": () => import("./../../../components/development/logo-page.tsx" /* webpackChunkName: "component---components-development-logo-page-tsx" */),
  "component---components-development-style-page-tsx": () => import("./../../../components/development/style-page.tsx" /* webpackChunkName: "component---components-development-style-page-tsx" */),
  "component---components-main-tsx": () => import("./../../../components/main.tsx" /* webpackChunkName: "component---components-main-tsx" */),
  "component---pages-community-tsx": () => import("./../../../pages/community.tsx" /* webpackChunkName: "component---pages-community-tsx" */),
  "component---pages-endpoint-tsx": () => import("./../../../pages/endpoint.tsx" /* webpackChunkName: "component---pages-endpoint-tsx" */),
  "component---pages-index-tsx": () => import("./../../../pages/index.tsx" /* webpackChunkName: "component---pages-index-tsx" */)
}

